import AOS from "aos";
import { Collapse, Modal } from "bootstrap";
import {getElementFromSelector} from "bootstrap/js/src/util";

class Landing {
    init()
    {
        this.recaptchaSiteKey = '6LdVsuUhAAAAAMpx365Oa9OcuvjahGDm2zDOCqxJ';

        this._init();

        this.formBtn = document.getElementById('contactForm').querySelector('button');
    }

    _init () {
        AOS.init();

        document.querySelectorAll('[data-bs-toggle="collapse"]')
            .forEach(popover => {
                new Collapse(popover)
            });

        document.querySelectorAll('[data-bs-toggle="modal"]')
            .forEach(popover => {
                popover.addEventListener('click', e => {
                    new Modal(document.querySelector(popover.dataset.target)).show();
                })
            });

        document.getElementById('goToServices').addEventListener('click', e => {
            e.preventDefault();
            const h2Rect = document.querySelector('.section-3').getBoundingClientRect();
            window.scrollTo(h2Rect.x, h2Rect.y - 75);
        });

        document.getElementById('contactForm').addEventListener('submit', this._onFormSubmit.bind(this));

        document.querySelectorAll('.goToContact').forEach(e => {
            e.addEventListener('click', ev => {
                ev.preventDefault();
                document.getElementById('contactForm').scrollIntoView({
                    behavior: "smooth",
                    block: "start"
                });
            });
        });

        document.querySelectorAll('.close').forEach(e => {
            e.addEventListener('click', ev => {
                ev.preventDefault();
                const el = document.getElementById('demo-request-modal');

                new Modal(el).dispose();

                el.classList.remove('show');
                el.setAttribute('aria-modal', 'false');
                el.style.display = 'none';
                document.getElementsByClassName('modal-backdrop')[0].remove();
                document.body.style = '';
            });
        });
    }

    async _onFormSubmit(event)
    {
        event.preventDefault();
        const values = {};

        grecaptcha.ready(() => {
            grecaptcha.execute(this.recaptchaSiteKey, {action: 'submit'}).then(async (token) => {
                values['recaptcha'] = token;

                event.target.querySelectorAll('input').forEach(el => {
                    values[el.id] = el.type === 'checkbox' ? el.checked : el.value;
                });

                this.toggleFormBtnState();
                const resp = await this._submitContact(values);
                this.toggleFormBtnState();

                if (resp) {
                    this._parseResponse(resp);
                } else {
                    alert('Ocurrió un error inesperado. Por favor inténtelo más tarde de nuevo. Gracias.');
                }
            });
        });
    }

    async _submitContact(values)
    {
        const url = '/back/contact.php';
        let rawResp = null;

        try {
            rawResp = await (await fetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                },
                body: JSON.stringify(values)
            })).json();
        } catch (e) { }

        return rawResp;
    }

    toggleFormBtnState()
    {
        if (this.formBtn.classList.contains('state-active'))
        {
            this.formBtn.classList.remove('state-active', 'disabled');
            return;
        }

        this.formBtn.classList.add('state-active', 'disabled');
    }

    _parseResponse(resp) {
        const alert = document.getElementById('alert');

        document.querySelectorAll('.is-invalid').forEach(e => e.classList.remove('is-invalid'));
        document.querySelectorAll('.feedback').forEach(e => e.classList.add('d-none'));
        alert.classList.remove('show');

        for (const item of resp.errors)
        {
            if (item.field === 'recaptcha')
                continue;

            const el = document.getElementById(item.field);
            el.classList.add('is-invalid');

            const feedback = el.parentElement.querySelector('.feedback');
            feedback.innerText = item.error;
            feedback.classList.add('text-danger');
            feedback.classList.remove('d-none');
        }

        if (resp.valid)
        {
            document.getElementById('contactForm').reset();
            alert.classList.add('show');
            setTimeout(() => {
                alert.classList.remove('show');
            }, 5000);
        }
    }

    demo() {
        this._init();

        document.getElementById('reque').addEventListener('click')
    }
}


export default new Landing;
